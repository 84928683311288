import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable, Subscription, map, startWith } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { environment } from '@env/environment';

import { SavedFilterItem } from './models/savedFilterItem';
import { SaveFilterDialogComponent } from './dialogs/save-filter-dialog/save-filter-dialog.component';
import {
  SaveFilterDialogAction,
  SaveFilterDialogParameters
} from './dialogs/save-filter-dialog/models/saveFilterDialogParameters';
import { DeleteItemDialogComponent } from './dialogs/delete-item-dialog/delete-item-dialog.component';
import { DeleteItemDialogParameters } from './dialogs/delete-item-dialog/models/deleteItemDialogParameters';
import { GridApi } from '@ag-grid-community/core';
import { CurrentFilterTypeAndValue } from '@models/currentFilterTypeAndValue';
import { SavedFiltersService } from '@services/saved-filters-service';

@Component({
  selector: 'pulse-grid-saved-filters',
  standalone: true,
  imports: [AsyncPipe, FontAwesomeModule, MatAutocompleteModule, ReactiveFormsModule],
  templateUrl: './saved-filters.component.html',
  styleUrl: './saved-filters.component.scss'
})
export class SavedFiltersComponent implements OnDestroy {
  baseURL = environment.apiUrl;

  /**
   * Value that uniquely identifies the grid who this component needs to be tied to.
   */
  @Input() gridIdentifier = '';

  /** The name of the saved filter that is currently active: Shown as a placeholder in the 'saved filters' area */
  @Input()
  set AppliedFilterName(name: string) {
    this._appliedFilterName = name;
  }
  _appliedFilterName = '';

  /**
   * Used to enable or disable the component. Typically the component is diabled UNTIL
   * data is loaded into the grid if this is a server side grid. If the parent grid is
   * using server side data then this will probably never be disabled.
   */
  @Input()
  set disable(flag: boolean) {
    if (flag) {
      this.savedFilterCtrl.disable();
    } else {
      this.savedFilterCtrl.enable();
    }
  }

  /**
   * Event that is fired when a saved filter is selected.
   */
  @Output() SavedFilterSelected: EventEmitter<SavedFilterItem> = new EventEmitter();

  /**
   * Event that is fired when a saved filter is deleted AND it was the filter that was active.
   * This SHOULD trigger the parent grid to clear its current filters.
   */
  @Output() ActiveSavedFilterDeleted: EventEmitter<boolean> = new EventEmitter();

  /** Subscription to the 'Show Save Dialog' observable */
  showDialogSubscription: Subscription;

  /** Icon to display on the "rename filter" button */
  renameFilterIcon = faEdit;

  /** Icon to display on the "delete filter" button */
  deleteFilterIcon = faTrash;

  /** The form control that gets the name of the saved filter that the user wants to run */
  savedFilterCtrl = new FormControl({ value: '', disabled: true });

  /** The saved filters object used to work with the autocomplete control */
  filteredSavedFilters: Observable<SavedFilterItem[]>;

  /**
   * All of the available saved filters example: { value: 'F1', name: 'Filter 1', filterType: 'Simple', filterValue: ''},
   */
  savedFilters: SavedFilterItem[] = [];

  /** Action to perform on the seleected saved filter */
  filterSelectedAction = '';

  /**
   * Fired when the user clicks on an object in the "saved filters" auto-complete object.
   * @param event Event object from the auto-complete object
   */
  filterSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option) {
      if (this.filterSelectedAction != 'D' && this.filterSelectedAction !== 'R') {
        const matchingFilter = this.savedFilters.find((x) => x.id === this.savedFilterCtrl.value!);
        this.SavedFilterSelected.emit(matchingFilter);
      }

      event.option.deselect();
      this.filterSelectedAction = '';
      this.refreshListOfSavedFilters();
    }
  }

  /**
   * Determines what saved filters pass the autocomplete filter search
   * @param value Value to search with to find matching saved filters
   * @returns List of saved filters that match the provided value
   */
  private _filterSavedFilters(value: string): SavedFilterItem[] {
    const filterValue = value.toLowerCase();
    return this.savedFilters.filter((filter) => filter.name.toLowerCase().includes(filterValue));
  }

  /**
   * Deletes a saved filter
   * @param filterId Id of the filter to delete
   */
  deleteSavedFilter(filterId: string) {
    this.filterSelectedAction = 'D';
    const idx = this.savedFilters.findIndex((x) => x.id === filterId);
    if (idx > -1) {
      const dialogRef = this.dialog.open(DeleteItemDialogComponent, {
        data: {
          title: 'Delete Saved Filter',
          description: `Are you sure you want to delete the saved filter '${this.savedFilters[idx].name}'`
        } as DeleteItemDialogParameters
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          // Save the name so we can use it for a future check
          const originalName = this.savedFilters[idx].name;

          // Actually remove this filter
          this.savedFilters.splice(idx, 1);

          // If we deleted the filter that is currently active then we need to remove it from the display
          if (this._appliedFilterName === originalName) {
            this.ActiveSavedFilterDeleted.emit(true);
          }

          // Refresh the list of saved filters to remove the one we just deleted
          this.refreshListOfSavedFilters();
        }
      });
    }
  }

  /**
   * Renames a saved filter
   * @param filterId Id of the filter to rename
   */
  renameSavedFilter(filterId: string) {
    this.filterSelectedAction = 'R';

    const idx = this.savedFilters.findIndex((x) => x.id === filterId);
    if (idx > -1) {
      const dialogRef = this.dialog.open(SaveFilterDialogComponent, {
        data: {
          action: 'Rename Existing Filter' as SaveFilterDialogAction,
          filterName: this.savedFilters[idx].name
        } as SaveFilterDialogParameters
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (typeof result === 'string' && result.trim().length > 0) {
          result = result.trim();

          this.savedFilters[idx].name = result;

          // Refresh the list of saved filters so we get the new name
          this.refreshListOfSavedFilters();
        }
      });
    }
  }

  /**
   * Refreshes the list of saved filters that will appear in the autocomplete control
   * when the user clicks into the autocomplete control.
   */
  refreshListOfSavedFilters() {
    this.savedFilterCtrl.setValue('');
  }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private filtersService: SavedFiltersService
  ) {
    this.filteredSavedFilters = this.savedFilterCtrl.valueChanges.pipe(
      startWith(''),
      map((filter) => {
        return filter ? this._filterSavedFilters(filter) : this.savedFilters.slice();
      })
    );

    this.showDialogSubscription = filtersService.showSaveDialog$.subscribe((grid) => this.showSaveFilterDialog(grid));
  }

  /**
   * Remove subscriptions here
   */
  ngOnDestroy() {
    this.showDialogSubscription.unsubscribe();
  }

  /**
   * Gets the filter type and values applied to the given grid
   * @param gridApi Reference to the grid who has the filter to save
   * @returns Controlled information about this grid's filter situation
   */
  getCurrentFilterTypeAndValue(gridApi: GridApi): CurrentFilterTypeAndValue {
    const advFilter = gridApi.getAdvancedFilterModel();
    if (advFilter !== null) {
      return { filterType: 'Advanced', filterValue: JSON.stringify(advFilter) };
    }

    const simpleFilter = gridApi.getFilterModel();
    if (simpleFilter !== null) {
      return {
        filterType: 'Simple',
        filterValue: JSON.stringify(simpleFilter)
      };
    }

    return { filterType: 'None', filterValue: '' };
  }

  /**
   * Displays a "Save Filter" dialog to the user and is called by the parent component.
   */
  showSaveFilterDialog(gridApi: GridApi) {
    // Exit early if no filter has been applied
    if (!gridApi!.isAnyFilterPresent() && gridApi!.getAdvancedFilterModel() === null) {
      return;
    }

    const currentFilter = this.getCurrentFilterTypeAndValue(gridApi!);
    if (currentFilter.filterType === 'None') {
      return;
    }

    const dialogRef = this.dialog.open(SaveFilterDialogComponent, {
      data: {
        action: 'Save New Filter' as SaveFilterDialogAction,
        filterName: ''
      } as SaveFilterDialogParameters
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'string' && result.trim().length > 0) {
        result = result.trim();

        const newFilter = {
          id: new Date().toISOString(),
          name: result,
          filterType: currentFilter.filterType,
          filterValue: currentFilter.filterValue
        } as SavedFilterItem;

        this.savedFilters.push(newFilter);

        this.refreshListOfSavedFilters();

        // Apply the new filter to make sure everything worked
        this.SavedFilterSelected.emit(newFilter);
      }
    });
  }
}
