import { ModuleNames } from '@ag-grid-community/core';
import { EnterpriseCoreModule } from '@ag-grid-enterprise/core';
import { MultiFilter } from './multiFilter/multiFilter.mjs';
import { MultiFloatingFilterComp } from './multiFilter/multiFloatingFilter.mjs';
import { VERSION } from './version.mjs';
export const MultiFilterModule = {
  version: VERSION,
  moduleName: ModuleNames.MultiFilterModule,
  beans: [],
  userComponents: [{
    componentName: 'agMultiColumnFilter',
    componentClass: MultiFilter
  }, {
    componentName: 'agMultiColumnFloatingFilter',
    componentClass: MultiFloatingFilterComp
  }],
  dependantModules: [EnterpriseCoreModule]
};