const fillFactory = {
  getTemplate(fill) {
    const {
      patternType,
      fgTheme,
      fgTint,
      fgRgb,
      bgRgb,
      bgIndexed
    } = fill;
    const pf = {
      name: 'patternFill',
      properties: {
        rawMap: {
          patternType
        }
      }
    };
    if (fgTheme || fgTint || fgRgb) {
      pf.children = [{
        name: 'fgColor',
        properties: {
          rawMap: {
            theme: fgTheme,
            tint: fgTint,
            rgb: fgRgb
          }
        }
      }];
    }
    if (bgIndexed || bgRgb) {
      if (!pf.children) {
        pf.children = [];
      }
      pf.children.push({
        name: 'bgColor',
        properties: {
          rawMap: {
            indexed: bgIndexed,
            rgb: bgRgb
          }
        }
      });
    }
    return {
      name: "fill",
      children: [pf]
    };
  }
};
export default fillFactory;