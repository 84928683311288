import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThemeEntry } from '@models/themeEntry';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(private http: HttpClient) {}

  getThemeOptions(): Observable<Array<ThemeEntry>> {
    return this.http.get<Array<ThemeEntry>>("assets/themes.json");
  }
 
  setTheme(themeToSet: string) {
    // TODO(@SiddAjmera): Implement this later
  }
}
