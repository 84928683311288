import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFillDrip } from '@fortawesome/free-solid-svg-icons';

import { DropdownModule } from 'primeng/dropdown';

import { environment } from '../environments/environment';
import { ToastsContainerComponent } from './services/toasts/toast-container.component';
import { ThemeEntry } from '@models/themeEntry';
import { ThemeService } from '@services/theme-service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    ToastsContainerComponent,
    NgOptimizedImage,
    DropdownModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.customerAbbreviation;

  /** Local flag for 'is prod'/'is dev' so we can show this on the UI */
  isProd = environment.production;

  /** The root URL for the API */
  apiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseApiUrl);

  themeIcon = faFillDrip;

  /**
   * All of the themes that are currently available to select from
   */
  availableThemes: ThemeEntry[] = [
    { name: 'Current', code: 'old' },
    { name: 'Muted', code: 'light' }
  ];

  /**
   * The model that binds to the theme selector UI component
   */
  themeGroup = new FormGroup({
    selectedTheme: new FormControl<ThemeEntry>(this.availableThemes[0])
  });

  constructor(
    private sanitizer: DomSanitizer,
    private themeService: ThemeService
    //private router: Router
  ) {
    //this.router.navigate(["general"]);

    this.themeGroup.valueChanges.subscribe((formValues) => {
      if (formValues.selectedTheme) {
        document.body.setAttribute('data-theme', formValues.selectedTheme!.code!);
      }
    });
  }
}
