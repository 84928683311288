import { Component, OnInit, inject } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';

import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IMultiFilterParams,
  Column,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilter, SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';

import { environment } from '@env/environment';

import { ContractorPurchaseOrderListingRecord } from './models/ContractorPurchaseOrderListingRecord';

import { TitleService } from '@services/title-service';
import { GridFilterType } from '@models/gridFilterType';
import { generalExcelStyles } from '@core/AgGridExtensions/ExcelExportStyles';
import { ContractorPoNumberCellRenderer } from './aggrid/cellRenderers/GCPoNumberCellRenderer';
import { StatusFilterComponent } from '@core/AgGridExtensions/ColumnFilters/status-filter/status-filter.component';
import { AgValidStatusType } from '@core/AgGridExtensions/Models/AgValidStatusType';
import { SavedFiltersComponent } from '../saved-filters/saved-filters.component';
import { SavedFilterItem } from '../saved-filters/models/savedFilterItem';
import { SavedFiltersService } from '@services/saved-filters-service';

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  MultiFilterModule,
  RowGroupingModule,
  SetFilterModule,
  MenuModule,
  ColumnsToolPanelModule,
  ClipboardModule
]);

@Component({
  selector: 'app-general-contractor-home-page',
  standalone: true,
  templateUrl: './general-contractor-home-page.component.html',
  styleUrl: './general-contractor-home-page.component.scss',
  imports: [
    AsyncPipe,
    CommonModule,
    AgGridModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    HighchartsChartModule,
    SavedFiltersComponent
  ]
})
export class GeneralContractorHomePageComponent implements OnInit {
  baseURL = environment.apiUrl;

  /**
   * The VERSION of the API to use for this component's calls
   */
  apiVersion = '1.0';

  getApiUrl(endPoint: string) {
    return `${this.baseURL}v${this.apiVersion}/${endPoint}`;
  }

  /**
   * 2024.02.12 - JAL - I'm pretty sure we aren't using this anymore.
   * Grab a reference to the title service so we can update the page title here.
   */
  titleService = inject(TitleService);

  //#region - Charts -

  public showCharts = false;

  showHideCharts() {
    this.showCharts = !this.showCharts;
  }

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    series: [
      {
        data: [15, 20, 7, 25, 42, 40, 33, 21, 4, 36],
        type: 'line'
      }
    ],
    title: {
      text: 'Change over time'
    },
    accessibility: {
      // Accessibility module options
      enabled: true
    }
  };

  //#endregion

  //#region - Saved Filters -

  /**
   * Used to enable/disable the "Save Filters" button. When the user applies a saved filter, we don't
   * want the button to be active because they have ALREADY saved the current filter. We
   * want the user to update the filter in the grid before the "Save Filters" button is (re)enabled.
   */
  allowSaveFilter = false;

  /**
   * Used to enable/disable the 'Saved Filters' component based on if data is loaded at all.
   */
  disableSaveFilter = true;

  /** The name of the saved filter that is currently active: Shown as a placeholder in the 'saved filters' area */
  nameOfActiveSavedFilter = '';

  /**
   * Flag to determine if the filter via the gridAPI via a saved filter or a custom filter column. We
   * want different behavior of the "save filters" button and possibly other objects based on the actual
   * source of a filter change.
   */
  IsSavedFilterApplied = false;

  activeFilterDeleted() {
    this.clearAllFilters();
  }

  applySavedFilter(filter: SavedFilterItem | undefined) {
    if (filter && filter.filterValue !== '') {
      this.nameOfActiveSavedFilter = filter.name;
      this.hasFilterApplied = true;
      this.allowSaveFilter = false;
      this.IsSavedFilterApplied = true;

      switch (filter.filterType) {
        case 'Simple':
          this.changeFilterType('Simple');
          this.gridApi.setFilterModel(JSON.parse(filter.filterValue));
          break;

        case 'Advanced':
          this.changeFilterType('Advanced');
          this.gridApi.setAdvancedFilterModel(JSON.parse(filter.filterValue));
          break;
      }
    }
  }

  saveCurrentFilters() {
    // Exit early if no filter has been applied
    if (!this.gridApi.isAnyFilterPresent() && this.gridApi.getAdvancedFilterModel() === null) {
      return;
    }

    this.filtersService.displaySaveDialog(this.gridApi);
  }

  //#endregion

  //#region - Grid -

  /** Reference to the ag-grid's API */
  gridApi!: GridApi<ContractorPurchaseOrderListingRecord>;

  /** The default width to use for date fields in the grid */
  private dateFieldWidth = 150;

  /**
   * Flag to specify if a filter has been applied or not. This is used by controls that should open work/be useable when
   * a filter IS applied.
   */
  hasFilterApplied = false;

  /**
   * Filter type currently being used in the grid.
   */
  filterStyle: GridFilterType = 'None'; // Advanced, Simple

  toggleAdvancedFilter() {
    if (this.filterStyle === 'Advanced') {
      this.changeFilterType('Simple');
    } else {
      this.changeFilterType('Advanced');
    }
  }

  changeFilterType(newType: GridFilterType) {
    // Only do something if the new type type is DIFFERENT from the current value
    if (this.filterStyle !== newType) {
      this.filterStyle = newType;

      switch (this.filterStyle) {
        case 'None':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: false
            },
            enableAdvancedFilter: false
          });
          break;

        case 'Simple':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: true
            },
            enableAdvancedFilter: false
          });
          break;

        case 'Advanced':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: false
            },
            enableAdvancedFilter: true
          });
          break;
      }
    }
  }

  /** The styles to use during Excel data exports */
  excelStyles = generalExcelStyles;

  /** The column definitions to use on this page */
  gridCols: ColDef<ContractorPurchaseOrderListingRecord>[] = [
    {
      field: 'idn',
      headerName: 'IDN',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'gpo',
      headerName: 'GPO',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'project',
      headerName: 'Parent Project #',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'childProject',
      headerName: 'Child Project #',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'facility.name',
      headerName: 'Facility Name',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'generalContractor.name',
      headerName: 'General Contractor',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'subContractor.name',
      headerName: 'Sub Contractor',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      cellClassRules: {
        redCell: (params) => params.value === ''
      },
      unSortIcon: true
    },
    {
      field: 'constructionStartDate',
      headerName: 'Construction Start Date',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      maxWidth: this.dateFieldWidth,
      minWidth: this.dateFieldWidth,
      width: this.dateFieldWidth,
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'completionDate',
      headerName: 'Completion Date',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      maxWidth: this.dateFieldWidth,
      minWidth: this.dateFieldWidth,
      width: this.dateFieldWidth,
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'vendor.name',
      headerName: 'Supplier',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true,
      cellClassRules: {
        redCell: (params) => params.value === ''
      }
    },
    {
      field: 'productCategory',
      headerName: 'Trade/Product Category',
      filter: SetFilter,
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'poNumber',
      headerName: 'PO Number',
      filter: 'agTextColumnFilter',
      cellRenderer: ContractorPoNumberCellRenderer,
      enableRowGroup: true,
      unSortIcon: true,
      cellClassRules: {
        redCell: (params) => {
          return params.value === '';
        }
      }
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'spendDate',
      headerName: 'Spend Date',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'qtyOrdered',
      headerName: 'Qty Ordered',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellClass: 'ag-right-aligned-cell',
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'item.tenantObjectId',
      headerName: 'Product',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'item.description',
      headerName: 'Product Name',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'pricePerEachSale',
      headerName: 'Sale Price/Each',
      cellDataType: 'number',
      cellClass: 'ag-right-aligned-cell',
      enableRowGroup: false,
      filter: 'agNumberColumnFilter',
      unSortIcon: true,
      valueFormatter: this.currencyCellValueFormatter
    },
    {
      field: 'pricePerEachMarket',
      headerName: 'Market Price/Each',
      cellDataType: 'number',
      cellClass: 'ag-right-aligned-cell',
      enableRowGroup: false,
      filter: 'agNumberColumnFilter',
      unSortIcon: true,
      valueFormatter: this.currencyCellValueFormatter
    },
    {
      field: 'pricePerEachList',
      headerName: 'List Price/Each',
      cellDataType: 'number',
      cellClass: 'ag-right-aligned-cell',
      enableRowGroup: false,
      filter: 'agNumberColumnFilter',
      unSortIcon: true,
      valueFormatter: this.currencyCellValueFormatter
    },
    {
      field: 'unitOfMeasure',
      headerName: 'Unit of Measure',
      cellDataType: 'text',
      enableRowGroup: false,
      filter: 'agTextColumnFilter',
      unSortIcon: true
    },
    {
      field: 'dateRequested',
      headerName: 'Date Requested',
      cellDataType: 'date',
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: StatusFilterComponent,
            filterParams: {
              availableStatus: [
                { id: '0', value: '' },
                { id: '1', value: 'Not Set' },
                { id: '2', value: 'Almost Due' },
                { id: '3', value: 'Late' }
              ] as AgValidStatusType[],
              statusColumn: 'dateRequestedErrorCode'
            }
          },
          {
            filter: 'agDateColumnFilter'
          }
        ]
      } as IMultiFilterParams,
      maxWidth: this.dateFieldWidth,
      minWidth: this.dateFieldWidth,
      width: this.dateFieldWidth,
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'etaDate',
      headerName: 'ETA Date',
      cellDataType: 'date',
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: StatusFilterComponent,
            filterParams: {
              availableStatus: [
                { id: '0', value: '' },
                { id: '1', value: 'Not Set' },
                { id: '2', value: 'Almost Due' },
                { id: '3', value: 'Due Today' },
                { id: '4', value: 'In the Future' }
              ] as AgValidStatusType[],
              statusColumn: 'etaDateErrorCode'
            }
          },
          {
            filter: 'agDateColumnFilter'
          }
        ]
      } as IMultiFilterParams,
      cellClassRules: {
        redCell: (params) => {
          return params.data?.etaDateErrorCode == 1;
        },
        orangeCell: (params) => {
          return params.data?.etaDateErrorCode == 2;
        },
        yellowCell: (params) => {
          return params.data?.etaDateErrorCode == 3;
        }
      },
      maxWidth: this.dateFieldWidth,
      minWidth: this.dateFieldWidth,
      width: this.dateFieldWidth,
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'qtyShipped',
      headerName: 'Qty Shipped',
      cellDataType: 'number',
      cellClass: 'ag-right-aligned-cell',
      enableRowGroup: false,
      filter: 'agNumberColumnFilter',
      unSortIcon: true
    },
    {
      field: 'dateShipped',
      headerName: 'Date Shipped',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      maxWidth: this.dateFieldWidth,
      minWidth: this.dateFieldWidth,
      width: this.dateFieldWidth,
      enableRowGroup: false,
      unSortIcon: true
    },
    {
      field: 'reasonLateName',
      headerName: 'Reason Late',
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      unSortIcon: true
    },
    {
      field: 'reasonLateComment',
      headerName: 'Reason Comment',
      filter: 'agTextColumnFilter',
      enableRowGroup: false,
      unSortIcon: true
    }
  ];

  /** The TOTAL number of rows in the entire data set */
  totalRowCount = 0;

  /** The text to display to the user that represents the number of results in the grid AND the number of total rows */
  rowCountText = '';

  /**
   * Flag used to know if data was loaded successfully or not so we can disable objects on the page until we DO have
   * data in the grid.
   */
  dataLoaded = false;

  onGridReady(params: GridReadyEvent<ContractorPurchaseOrderListingRecord>) {
    this.gridApi = params.api;

    this.gridApi.updateGridOptions({
      enableAdvancedFilter: false,
      rowSelection: 'single', // allow rows to be selected
      animateRows: true, // have rows animate to new positions when sorted
      suppressMenuHide: true,
      groupDisplayType: 'groupRows',
      rowGroupPanelShow: 'always', // Display the "grouping header" when one or more columns have been grouped
      showOpenedGroup: true,
      headerHeight: 40, // The height of the header
      suppressCsvExport: true, // Don't allow a CSV export
      suppressExcelExport: false, // DO allow an EXCEL export
      defaultExcelExportParams: {
        allColumns: false,
        author: 'Lumatrak',
        sheetName: 'Projects',
        fileName: `Projects_${new Date().toISOString().substring(0, 10)}.xlsx`
      }
    });

    this.changeFilterType('Simple');
  }

  onFilterChanged(params: FilterChangedEvent<ContractorPurchaseOrderListingRecord>) {
    console.log(`entered onFilterChanged: params.source = ${params.source}`);
    this.displayRecordCount();

    // Determine what type of filter is being used
    switch (params.source) {
      case 'api':
        if (this.IsSavedFilterApplied) {
          this.hasFilterApplied = true;
          this.allowSaveFilter = false;
        } else {
          this.nameOfActiveSavedFilter = '';
          this.hasFilterApplied = params.api.isAnyFilterPresent();
          this.allowSaveFilter = true;
        }
        break;

      case 'advancedFilter': {
        const advModel = params.api.getAdvancedFilterModel();

        // Set the "has filter" flag
        this.hasFilterApplied = advModel !== null;
        this.nameOfActiveSavedFilter = '';
        this.allowSaveFilter = this.hasFilterApplied;
        break;
      }

      case 'quickFilter':
      case 'columnFilter': {
        // This is to update the style of a column header when we do an Excel Export. This change
        // ISN'T reflected on the webpage itself since other CSS clases are used for that instead.
        const appliedFilters = params.api.getFilterModel();
        const filteredColumnsMap = new Map<string, boolean>();
        for (const key in appliedFilters) {
          filteredColumnsMap.set(key, true);
        }
        const allColumns = params.api.getColumns() as Column[];
        allColumns.forEach((col) => {
          const colId = col.getColId();
          if (filteredColumnsMap.has(colId)) {
            params.api.getColumnDef(colId)!.headerClass = 'filteredColumn';
          } else {
            params.api.getColumnDef(colId)!.headerClass = 'unfilteredColumn';
          }
        });

        // Set the "has filter" flag
        this.hasFilterApplied = filteredColumnsMap.size > 0;
        this.nameOfActiveSavedFilter = '';
        this.allowSaveFilter = this.hasFilterApplied;
        break;
      }
    }

    // Set this back to false no matter what
    this.IsSavedFilterApplied = false;

    console.info(`this.allowSaveFilter = ${this.allowSaveFilter}`);
  }

  onFirstDataRendered(event: FirstDataRenderedEvent<ContractorPurchaseOrderListingRecord>) {
    this.displayRecordCount();
    event.api.autoSizeAllColumns();
  }

  /**
   * Displays the current record count including number matching any active filter and total record count
   */
  displayRecordCount() {
    if (this.gridApi.isAnyFilterPresent()) {
      this.rowCountText = `${this.gridApi.getModel().getRowCount().toLocaleString()} of ${this.totalRowCount.toLocaleString()}`;
    } else {
      this.rowCountText = `${this.totalRowCount.toLocaleString()}`;
    }
  }

  currencyCellValueFormatter(params: ValueFormatterParams<ContractorPurchaseOrderListingRecord>) {
    if (!params.value) {
      return '';
    }
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return currencyFormatter.format(params.value);
  }

  //#endregion

  constructor(
    private http: HttpClient,
    private filtersService: SavedFiltersService
  ) {}

  ngOnInit(): void {
    this.loadRecordsFromServer();
  }

  /**
   * Loads the data in the grid from the server.
   */
  loadRecordsFromServer() {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');

    this.http
      .get<
        ContractorPurchaseOrderListingRecord[]
      >(this.getApiUrl('Contractors/ContractorSummary'), { headers: headers })
      .subscribe({
        next: (data) => {
          data.forEach((r) => (r.idn = environment.customerAbbreviation));

          this.gridApi.setGridOption('rowData', data);
          this.totalRowCount = this.gridApi.getModel()?.getRowCount() ?? 0;
          if (this.totalRowCount > 0) {
            this.dataLoaded = true;
            this.disableSaveFilter = false;
          } else {
            this.dataLoaded = false;
            this.disableSaveFilter = true;
          }
        },
        error: (e) => {
          console.error(e);
          this.dataLoaded = false;
          this.disableSaveFilter = true;
        }
      });
  }

  /**
   * Removes all filters from the grid
   */
  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.setAdvancedFilterModel(null);

    // Affect controls realted to having filtered data
    this.hasFilterApplied = false;

    // Related to saved filters
    this.nameOfActiveSavedFilter = '';
  }

  /**
   * Exports the grid data as an Excel file
   */
  exportToExcel() {
    this.gridApi.exportDataAsExcel();
  }
}
