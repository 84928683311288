import { ICellRendererComp, ICellRendererParams } from '@ag-grid-community/core';
import { ContractorPurchaseOrderListingRecord } from '../../models/ContractorPurchaseOrderListingRecord';
import { PulseThemeColors } from '@styling/PulseTheme';

/** This cell renderer is for PO numbers so we can add a custom background to the cell based on an "error code" value */
export class ContractorPoNumberCellRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  // init method gets the details of the cell to be renderer
  init(params: ICellRendererParams<ContractorPurchaseOrderListingRecord, string>) {
    this.eGui = document.createElement('span');
    this.eGui.innerHTML = params.value!;

    const value = params.data?.poNumberErrorCode ?? 0;
    if (value > 0) {
      const colors = this.getColors(value);
      params.eGridCell.style.color = colors.fontColor;
      params.eGridCell.style.backgroundColor = colors.backgroundColor;
    }
  }

  getColors = (errorCode: number) => {
    switch (errorCode) {
      case 1:
        return {
          backgroundColor: PulseThemeColors.OrangeStatus.backgroundColor,
          fontColor: PulseThemeColors.OrangeStatus.color
        };

      case 2:
        return {
          backgroundColor: PulseThemeColors.YellowStatus.backgroundColor,
          fontColor: PulseThemeColors.YellowStatus.color
        };

      default:
        return {
          backgroundColor: PulseThemeColors.RedStatus.backgroundColor,
          fontColor: PulseThemeColors.RedStatus.color
        };
    }
  };

  getGui() {
    return this.eGui;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
