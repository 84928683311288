import { HttpResponse, HttpInterceptorFn, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { tap } from 'rxjs';

export const iso8601ToJsonDateHttpInterceptor = () => {
  /**
   * Regex that matches ISO8601 formatted (yyyyMMdd) dates
   */
  const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const convertToDate = (body: any) => {
    if (body === null || body === undefined || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIso8601(value)) {
        // The value from the server is coming over as UTC
        //console.info(`ISO8601 Conversion (${value} => ${body[key]})`);
        // 2023-12-28T00:00:00Z => Wed Dec 27 2023 18:00:00 GMT-0600 (Central Standard Time)
        //body[key] = new Date(value);

        // This will parse out the year/month/day and pretend it is from the local time zone
        //console.info(`${value} = ${value.substring(0, 4)}.${value.substring(5, 7)}.${value.substring(8, 10)}`);
        body[key] = new Date(value.substring(0, 4), value.substring(5, 7) - 1, value.substring(8, 10));
      } else if (typeof value === 'object') {
        convertToDate(value);
      }
    }
  };

  /**
   * Processes and object to determine if it is a date in the ISO8601 format or not.
   * @param value Raw value to process to see if it is a date or not
   * @returns True is returned if this value IS a date in the ISO8601 format, otherwise False is returned.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isIso8601 = (value: any) => {
    if (value === null || value === undefined) {
      return false;
    }

    return iso8601.test(value);
  };

  const interceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    return next(req).pipe(
      tap({
        next: (response) => {
          if (response instanceof HttpResponse) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const body = (response satisfies HttpResponse<any>).body;
            convertToDate(body);
          }
        }
      })
    );
  };

  return interceptor;
};
