import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

/**
 * The default title we are using for the site (sort of like a page title prefix)
 */
const DEFAULT_TITLE = 'Pulse';

@Injectable({ providedIn: 'root' })
export class TitleService implements OnDestroy {
  /**
   * Initial page title, used to merge with the additions to the title when we navigate to other pages
   */
  title$ = new BehaviorSubject<string>(DEFAULT_TITLE);

  /**
   * Subscription used to update the title. This exists so we can unsubscribe from it during destroy.
   */
  titleSubscription: Subscription;

  /**
   * The observable used to hold the title of the current page (minus the DEFAULT_TITLE).
   */
  private titleRoute$: Observable<string | undefined> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.generatePageTitle(this.activatedRoute.firstChild))
  );

  /**
   * The observable used to hold the title prefix + the generated title for the current page.
   */
  private titleState$ = merge(this.title$, this.titleRoute$).pipe(
    filter((title) => title !== undefined),
    tap((title) => {
      this.titleService.setTitle(`${DEFAULT_TITLE}: ${title}`);
    })
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleSubscription = this.titleState$.subscribe();
  }
  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }

  /**
   * Generates the title for the current page based on the current route
   * @param activatedRoute The currently route
   * @returns String that represents the title of the current page
   */
  private generatePageTitle(activatedRoute: ActivatedRoute | null): string | undefined {
    while (activatedRoute) {
      if (activatedRoute.firstChild) {
        activatedRoute = activatedRoute.firstChild;
      } else if (activatedRoute.snapshot.data && activatedRoute.snapshot.data['pageTitle']) {
        return activatedRoute.snapshot.data['pageTitle'] as string;
      } else {
        return undefined;
      }
    }
    return undefined;
  }
}
