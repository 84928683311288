<h2 mat-dialog-title>{{ action }}</h2>

<mat-dialog-content style="display: flex; flex-direction: column">
  <form>
    <mat-form-field style="width: 100%">
      <mat-label>Filter Name</mat-label>
      <input
        matInput
        [(ngModel)]="filterName"
        [ngModelOptions]="{standalone: true}"
        placeholder="Name to describe the filter"
        style="width: 100%"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button (click)="OkClick()" mat-button cdkFocusInitial>OK</button>
</mat-dialog-actions>
