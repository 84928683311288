import alignmentFactory from './alignment.mjs';
import protectionFactory from './protection.mjs';
const xfFactory = {
  getTemplate(xf) {
    const {
      alignment,
      borderId,
      fillId,
      fontId,
      numFmtId,
      protection,
      xfId
    } = xf;
    const children = [];
    if (alignment) {
      children.push(alignmentFactory.getTemplate(alignment));
    }
    if (protection) {
      children.push(protectionFactory.getTemplate(protection));
    }
    return {
      name: "xf",
      properties: {
        rawMap: {
          applyAlignment: alignment ? 1 : undefined,
          applyProtection: protection ? 1 : undefined,
          applyBorder: borderId ? 1 : undefined,
          applyFill: fillId ? 1 : undefined,
          borderId,
          fillId,
          applyFont: fontId ? 1 : undefined,
          fontId,
          applyNumberFormat: numFmtId ? 1 : undefined,
          numFmtId,
          xfId
        }
      },
      children: children.length ? children : undefined
    };
  }
};
export default xfFactory;