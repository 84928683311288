<div class="ag-filter-wrapper ag-focus-managed">
    <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper" ref="eFilterBody" style="max-height: 788px;">
        <div class="ag-filter-body">
            <label>
                Status:
                <select title="Status filter" name="StatusFilter" [(ngModel)]="statusValue">
                    @for(c of validStatusComparisons; track c) {
                        <option value="{{ c.id }}">{{ c.value }}</option>
                    }
                </select>
            </label>
        </div>
    
        <button title="Apply Status Filter" (click)="applyStatusFilter()">Apply Status Filter</button>
    </div>
</div>