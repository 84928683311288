<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content>
    {{ description }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="CancelClick()" mat-button mat-dialog-close>No</button>
  <button (click)="OkClick()" mat-button cdkFocusInitial>Yes</button>
</mat-dialog-actions>
