var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '../../../widgets/component.mjs';
import { RefSelector } from '../../../widgets/componentAnnotations.mjs';
import { Autowired } from '../../../context/context.mjs';
// optional floating filter for user provided filters - instead of providing a floating filter,
// they can provide a getModelAsString() method on the filter instead. this class just displays
// the string returned from getModelAsString()
export class ReadOnlyFloatingFilter extends Component {
  constructor() {
    super( /* html */`
            <div class="ag-floating-filter-input" role="presentation">
                <ag-input-text-field ref="eFloatingFilterText"></ag-input-text-field>
            </div>`);
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    const displayName = this.columnModel.getDisplayNameForColumn(params.column, 'header', true);
    const translate = this.localeService.getLocaleTextFunc();
    this.eFloatingFilterText.setDisabled(true).setInputAriaLabel(`${displayName} ${translate('ariaFilterInput', 'Filter Input')}`);
  }
  onParentModelChanged(parentModel) {
    if (parentModel == null) {
      this.eFloatingFilterText.setValue('');
      return;
    }
    this.params.parentFilterInstance(filterInstance => {
      // it would be nice to check if getModelAsString was present before creating this component,
      // however that is not possible, as React Hooks and VueJS don't attached the methods to the Filter until
      // AFTER the filter is created, not allowing inspection before this (we create floating filters as columns
      // are drawn, but the parent filters are only created when needed).
      if (filterInstance.getModelAsString) {
        const modelAsString = filterInstance.getModelAsString(parentModel);
        this.eFloatingFilterText.setValue(modelAsString);
      }
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.init(params);
  }
}
__decorate([RefSelector('eFloatingFilterText')], ReadOnlyFloatingFilter.prototype, "eFloatingFilterText", void 0);
__decorate([Autowired('columnModel')], ReadOnlyFloatingFilter.prototype, "columnModel", void 0);