import { ApplicationConfig } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { iso8601ToJsonDateHttpInterceptor } from './core/HttpInterceptors/iso8601ToJsonDateHttpInterceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastService } from './services/toasts/toast-service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withFetch(), withInterceptors([iso8601ToJsonDateHttpInterceptor()])),
    provideAnimations(),
    ToastService
  ]
};
