export const ADVANCED_FILTER_LOCALE_TEXT = {
  ariaAdvancedFilterBuilderItem: variableValues => `${variableValues[0]}. Level ${variableValues[1]}. Press ENTER to edit.`,
  ariaAdvancedFilterBuilderItemValidation: variableValues => `${variableValues[0]}. Level ${variableValues[1]}. ${variableValues[2]} Press ENTER to edit.`,
  ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
  ariaAdvancedFilterBuilderFilterItem: 'Filter Condition',
  ariaAdvancedFilterBuilderGroupItem: 'Filter Group',
  ariaAdvancedFilterBuilderColumn: 'Column',
  ariaAdvancedFilterBuilderOption: 'Option',
  ariaAdvancedFilterBuilderValue: 'Value',
  ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
  ariaAdvancedFilterInput: 'Advanced Filter Input',
  ariaLabelAdvancedFilterAutocomplete: 'Advanced Filter Autocomplete',
  advancedFilterContains: 'contains',
  advancedFilterNotContains: 'does not contain',
  advancedFilterTextEquals: 'equals',
  advancedFilterTextNotEqual: 'does not equal',
  advancedFilterStartsWith: 'begins with',
  advancedFilterEndsWith: 'ends with',
  advancedFilterBlank: 'is blank',
  advancedFilterNotBlank: 'is not blank',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'is true',
  advancedFilterFalse: 'is false',
  advancedFilterAnd: 'AND',
  advancedFilterOr: 'OR',
  advancedFilterApply: 'Apply',
  advancedFilterBuilder: 'Builder',
  advancedFilterValidationMissingColumn: 'Column is missing',
  advancedFilterValidationMissingOption: 'Option is missing',
  advancedFilterValidationMissingValue: 'Value is missing',
  advancedFilterValidationInvalidColumn: 'Column not found',
  advancedFilterValidationInvalidOption: 'Option not found',
  advancedFilterValidationMissingQuote: 'Value is missing an end quote',
  advancedFilterValidationNotANumber: 'Value is not a number',
  advancedFilterValidationInvalidDate: 'Value is not a valid date',
  advancedFilterValidationMissingCondition: 'Condition is missing',
  advancedFilterValidationJoinOperatorMismatch: 'Join operators within a condition must be the same',
  advancedFilterValidationInvalidJoinOperator: 'Join operator not found',
  advancedFilterValidationMissingEndBracket: 'Missing end bracket',
  advancedFilterValidationExtraEndBracket: 'Too many end brackets',
  advancedFilterValidationMessage: variableValues => `Expression has an error. ${variableValues[0]} - ${variableValues[1]}.`,
  advancedFilterValidationMessageAtEnd: variableValues => `Expression has an error. ${variableValues[0]} at end of expression.`,
  advancedFilterBuilderTitle: 'Advanced Filter',
  advancedFilterBuilderApply: 'Apply',
  advancedFilterBuilderCancel: 'Cancel',
  advancedFilterBuilderAddButtonTooltip: 'Add Filter or Group',
  advancedFilterBuilderRemoveButtonTooltip: 'Remove',
  advancedFilterBuilderMoveUpButtonTooltip: 'Move Up',
  advancedFilterBuilderMoveDownButtonTooltip: 'Move Down',
  advancedFilterBuilderAddJoin: 'Add Group',
  advancedFilterBuilderAddCondition: 'Add Filter',
  advancedFilterBuilderSelectColumn: 'Select a column',
  advancedFilterBuilderSelectOption: 'Select an option',
  advancedFilterBuilderEnterValue: 'Enter a value...',
  advancedFilterBuilderValidationAlreadyApplied: 'Current filter already applied.',
  advancedFilterBuilderValidationIncomplete: 'Not all conditions are complete.',
  advancedFilterBuilderValidationSelectColumn: 'Must select a column.',
  advancedFilterBuilderValidationSelectOption: 'Must select an option.',
  advancedFilterBuilderValidationEnterValue: 'Must enter a value.'
};