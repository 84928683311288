<form class="headerItem">
    <input type="text"
           aria-label="Saved Filter"
           [placeholder]="_appliedFilterName"
           [matAutocomplete]="auto"
           [formControl]="savedFilterCtrl">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filterSelected($event)" panelWidth="auto">
      @for (fltr of filteredSavedFilters | async; track fltr) {
        <mat-option [value]="fltr.id">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <button class="SavedFilterActionButton" (click)="renameSavedFilter(fltr.id)" title="Rename filter"><fa-icon [icon]="renameFilterIcon"></fa-icon></button>
                <span class="SavedFilterText">{{fltr.name}}</span>
                <button class="SavedFilterActionButton" (click)="deleteSavedFilter(fltr.id)" title="Delete filter"><fa-icon [icon]="deleteFilterIcon"></fa-icon></button>
            </div>
        </mat-option>
      }
    </mat-autocomplete>
</form>