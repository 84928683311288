import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SaveFilterDialogParameters } from './models/saveFilterDialogParameters';

@Component({
  selector: 'pulse-save-filter-dialog',
  standalone: true,
  imports: [FormsModule, MatDialogModule, MatInputModule, MatButtonModule, MatFormFieldModule],
  templateUrl: './save-filter-dialog.component.html',
  styleUrl: './save-filter-dialog.component.scss'
})
export class SaveFilterDialogComponent {
  action = '';
  filterName = '';

  constructor(
    public dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveFilterDialogParameters
  ) {
    this.action = data.action;
    this.filterName = data.filterName;
  }

  OkClick() {
    this.dialogRef.close(this.filterName);
  }
}
