const sheetFactory = {
  getTemplate(name, idx) {
    const sheetId = (idx + 1).toString();
    return {
      name: "sheet",
      properties: {
        rawMap: {
          "name": name,
          "sheetId": sheetId,
          "r:id": `rId${sheetId}`
        }
      }
    };
  }
};
export default sheetFactory;