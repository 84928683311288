export const FILTER_LOCALE_TEXT = {
  applyFilter: 'Apply',
  clearFilter: 'Clear',
  resetFilter: 'Reset',
  cancelFilter: 'Cancel',
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',
  filterOoo: 'Filter...',
  empty: 'Choose one',
  equals: 'Equals',
  notEqual: 'Does not equal',
  lessThan: 'Less than',
  greaterThan: 'Greater than',
  inRange: 'Between',
  inRangeStart: 'From',
  inRangeEnd: 'To',
  lessThanOrEqual: 'Less than or equal to',
  greaterThanOrEqual: 'Greater than or equal to',
  contains: 'Contains',
  notContains: 'Does not contain',
  startsWith: 'Begins with',
  endsWith: 'Ends with',
  blank: 'Blank',
  notBlank: 'Not blank',
  before: 'Before',
  after: 'After',
  andCondition: 'AND',
  orCondition: 'OR',
  dateFormatOoo: 'yyyy-mm-dd'
};