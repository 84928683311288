<mat-tab-group>
    <mat-tab label="Angular Material"> 
        <a href="https://material.angular.io/components/dialog/examples">Dialog Examples</a><br />
        <a href="https://material.angular.io/components/progress-bar/overview">Progress Bar Examples</a>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <mat-slide-toggle
          class="example-margin">
        Slide me!
      </mat-slide-toggle>
        <mat-spinner></mat-spinner>

        <mat-form-field>
            <mat-label>Snack bar duration (seconds)</mat-label>
            <input title="ignore" type="number" [(ngModel)]="durationInSeconds" matInput>
        </mat-form-field>
            
        <button mat-stroked-button (click)="openSnackBar()" aria-label="Show an example snack-bar">
            Pizza party
        </button>
            
            
        <mat-chip-listbox aria-label="Fish selection">
            <mat-chip-option>One fish</mat-chip-option>
            <mat-chip-option disabled>Two fish</mat-chip-option>
            <mat-chip-option color="accent" selected>Accent fish</mat-chip-option>
            <mat-chip-option color="warn">Warn fish</mat-chip-option>
        </mat-chip-listbox>
    </mat-tab>
    <mat-tab label="Bootstrap">
        <a href="https://ng-bootstrap.github.io/#/components/dropdown/examples">Examples</a>
    </mat-tab>
    <mat-tab label="Angular Form">
        <form [formGroup]="profileForm">
            <mat-form-field [floatLabel]="'always'">
                <mat-label>First Name</mat-label>
                <input title="User's first name" alt="First Name" matInput required placeholder="" formControlName="firstName">
            </mat-form-field>
            <mat-form-field [floatLabel]="'always'">
                <mat-label>Last Name</mat-label>
                <input title="User's last name" alt="Last Name" matInput required placeholder="" formControlName="lastName">
            </mat-form-field>

            <mat-form-field
                [floatLabel]="'always'">
                <mat-select required formControlName="supervisor">
                    <mat-option>-- None --</mat-option>
                    <mat-option value="option">Option</mat-option>
                </mat-select>
                <mat-label>Supervisor</mat-label>
            </mat-form-field>
          </form>
        <p>First Name: {{ profileForm.value.firstName }}</p>
        <p>Last Name: {{ profileForm.value.lastName }}</p>
        <p>Supervisor: {{ profileForm.value.supervisor }}</p>
        <p>Form Status: {{ profileForm.status }}</p>
    </mat-tab>
    <mat-tab label="Toasts">
        <div class="buttons">
            <button class="btn btn-primary" (click)="showStandard(standardTpl)" ngbTooltip="Will disappear in 5s">Standard toast</button>&nbsp;
            <button class="btn btn-success" (click)="showSuccess(successTpl)" ngbTooltip="Will disappear in 10s">Success toast</button>&nbsp;
            <button class="btn btn-danger" (click)="showDanger(dangerTpl)" ngbTooltip="Will disappear in 15s">Danger toast</button>&nbsp;
        </div>
    </mat-tab>
</mat-tab-group>

<ng-template #standardTpl> I'm a standard toast </ng-template>

<ng-template #successTpl> I'm a success toast </ng-template>

<ng-template #dangerTpl>
	<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
		<path
			d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z"
		/>
	</svg>
	Danger Danger !
</ng-template>