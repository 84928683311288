import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';

import { AgValidStatusType } from '../../Models/AgValidStatusType';
import { StatusFilterModel } from './models/status-filter-model';
import { StatusFilterParams } from './models/status-filter-params';

@Component({
  selector: 'app-status-filter',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './status-filter.component.html',
  styleUrl: './status-filter.component.scss'
})
export class StatusFilterComponent implements IFilterAngularComp {
  /** The parameters passed to this filter */
  filterParams!: IFilterParams;

  /** Status code filter value */
  statusValue = '0';

  /* The status code in the value it NEEDS to be in to do the comparison */
  actualStatusValue = 0;

  /** The field tha thas the status value to use for filtering */
  statusColumnName = '';

  /** The valid STATUS comparisons available in this filter */
  validStatusComparisons: AgValidStatusType[] | null = null;

  agInit(params: IFilterParams): void {
    this.filterParams = params;

    const p = params as unknown as StatusFilterParams;
    this.validStatusComparisons = p.availableStatus;
    this.statusColumnName = p.statusColumn;
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    // Exit early if there is no filter applied
    if (this.actualStatusValue === 0) {
      return true;
    }

    // We are NOT going to check to see if this property already exists because it is up to the programmer to make sure it does!!!
    //if (params.data.hasOwnProperty(this.statusColumnName)) {
    return params.data[this.statusColumnName] === this.actualStatusValue;
    //}

    //return false;
  }

  isFilterActive(): boolean {
    return this.statusValue !== '0';
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }

    return {
      statusValue: this.statusValue
    };
  }

  setModel(model: StatusFilterModel) {
    console.info(`entered setModel: ${model}`);
    if (model === null) {
      this.statusValue = '0';
      this.actualStatusValue = 0;
    } else {
      this.statusValue = model.statusValue;
      this.actualStatusValue = Number(this.statusValue);
    }
  }

  /**
   * Attemps to apply the status field logic to filter data
   */
  applyStatusFilter() {
    console.info(`entered applyStatusFilter`);
    this.actualStatusValue = Number(this.statusValue);
    this.filterParams.filterChangedCallback();
  }
}
