<!-- Prefetch for our API -->
<link rel="preconnect" [href]="apiUrl" />

<div class="pageContainer">
  <header>
    <mat-toolbar>
      <img ngSrc="/assets/pulse-logo-tm.png" aria-label="Lumatrak Pulse Logo" width="175" height="55" style="margin-right: 20px;" priority alt="PULSE logo">
      <button style="visibility: hidden;" mat-button routerLink="/general" routerLinkActive="active" routerLinkActive="mat-mdc-raised-button" ariaCurrentWhenActive="page">General Contractor</button>
      <button style="visibility: hidden;" mat-button routerLink="/pharmacy" routerLinkActive="active" routerLinkActive="mat-mdc-raised-button" ariaCurrentWhenActive="page">Pharmacy</button>
      <button style="visibility: hidden;" mat-button routerLink="/examples" routerLinkActive="active" routerLinkActive="mat-mdc-raised-button" ariaCurrentWhenActive="page">Examples</button>
      <span class="spacer"></span>
      <div class="ThemeSwitcher">
        <form [formGroup]="themeGroup" [style]="{ 'height': '44px' }">
          <!-- 
          <button mat-mini-fab aria-label="Change themes">
            <mat-icon style="color: var(--pulse-logo-color);">palette</mat-icon>
          </button>
          -->
          <p-dropdown class="ThemeDropdown" formControlName="selectedTheme" [options]="availableThemes" optionLabel="name" placeholder="Theme to use"></p-dropdown>
        </form>
      </div>
      <div class="MyAccount">
        <button mat-icon-button title="Manage User Profile">
          <mat-icon style="color: var(--pulse-logo-color);">account_circle</mat-icon>
        </button>
        <span style='font-size: 8pt;'>My Account</span>
      </div>
    </mat-toolbar>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    Copyright © 2024 Lumatrak, Inc.
  </footer>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>