<div class="componentContainer">    
    @if(showCharts) {
        <div id="Charts">
            <highcharts-chart 
                [Highcharts]="Highcharts"
                [options]="chartOptions"
                class="chartParent"
                ></highcharts-chart>
        </div>
    }
    @else {
        <div></div>
    } 
    <mat-card class="headerContainer">
        <pulse-grid-saved-filters 
            [gridIdentifier]="'contractor'" 
            [disable]="disableSaveFilter"
            [AppliedFilterName]="nameOfActiveSavedFilter"
            (ActiveSavedFilterDeleted)="activeFilterDeleted()"
            (SavedFilterSelected)="applySavedFilter($event)">
        </pulse-grid-saved-filters>

        <button class="SaveFilterButton headerItem" [ngClass]="dataLoaded && hasFilterApplied && allowSaveFilter ? '' : 'DisabledButton'" [disabled]="!(dataLoaded && hasFilterApplied && allowSaveFilter)" (click)="saveCurrentFilters()">Save Filters</button>
        <button class="ClearFiltersButton headerItem" [ngClass]="dataLoaded && hasFilterApplied ? '' : 'DisabledButton'" [disabled]="!(dataLoaded && hasFilterApplied)" (click)="clearAllFilters()">Clear Filters</button>
        <mat-slide-toggle class="flipper headerItem" [disabled]="!dataLoaded" [checked]="filterStyle === 'Advanced'" (change)="toggleAdvancedFilter()">Advanced Filter</mat-slide-toggle>
        <span class="spacer"></span>
        <!--
        <mat-button-toggle-group name="filterType" aria-label="Grid Filter Type" [value]="filterStyle">
            <mat-button-toggle value="None" (change)="changeFilterType('None')">None</mat-button-toggle>
            <mat-button-toggle value="Simple" (change)="changeFilterType('Simple')">Simple</mat-button-toggle>
            <mat-button-toggle value="Advanced" (change)="changeFilterType('Advanced')">Advanced</mat-button-toggle>
          </mat-button-toggle-group>
          -->
          <mat-slide-toggle class="flipper headerItem" [disabled]="!dataLoaded" [checked]="showCharts" (change)="showHideCharts()">Show Charts</mat-slide-toggle>
        @if (!this.dataLoaded) {
            <button class="ExcelExportButton  headerItemDisabledButton" [disabled]="true" (click)="exportToExcel()">Excel Export</button>
        }
        @else {
            <button class="ExcelExportButton headerItem" (click)="exportToExcel()">Excel Export</button>
        }
    </mat-card>
    <ag-grid-angular
        class="grid ag-theme-alpine"
        [columnDefs]="gridCols"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        [excelStyles]="excelStyles"
        >
    </ag-grid-angular>
    <mat-card class="footerContainer">
        Number of records: {{ rowCountText }}
    </mat-card>
</div>