export type PulseColor =
  | 'UnFilteredGridHeader'
  | 'FilteredGridHeader'
  | 'GreenStatus'
  | 'OrangeStatus'
  | 'RedStatus'
  | 'YellowStatus';

export type PulseColorTheme = {
  color: string;
  backgroundColor: string;
};

export const PulseThemeColors: Record<PulseColor, PulseColorTheme> = {
  UnFilteredGridHeader: { color: '#000000', backgroundColor: '#8eb0d0' },
  FilteredGridHeader: { color: '#FFFFFF', backgroundColor: '#008001' },
  GreenStatus: { color: '#FFFFFF', backgroundColor: '#008001' },
  OrangeStatus: { color: '#000000', backgroundColor: '#ffa500' },
  RedStatus: { color: '#000000', backgroundColor: '#ff0000' },
  YellowStatus: { color: '#000000', backgroundColor: '#ffff00' }
};
