import { Routes } from '@angular/router';
import { GeneralContractorHomePageComponent } from './components/general-contractor-home-page/general-contractor-home-page.component';
import { ExamplesComponent } from './components/examples/examples.component';
import { PharmacyOverviewComponent } from './components/pharmacy-overview/pharmacy-overview.component';
import { environment } from '@env/environment';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

/**
 * List of routes to use in the application. Default to the General Contractor component for now.
 */
export const routes: Routes = [
  {
    path: 'general',
    component: GeneralContractorHomePageComponent,
    data: { pageTitle: 'Supplier Management' }
  },
  {
    path: 'pharmacy',
    component: PharmacyOverviewComponent,
    data: { pageTitle: 'Pharmacy Overview' }
  },
  {
    path: 'examples',
    component: ExamplesComponent,
    data: { pageTitle: 'Examples' }
  },
  { path: '', redirectTo: `${environment.defaultPath}`, pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { pageTitle: 'Page Not Found' }
  }
];
