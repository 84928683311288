import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DeleteItemDialogParameters } from './models/deleteItemDialogParameters';

@Component({
  selector: 'pulse-delete-item-dialog',
  standalone: true,
  imports: [FormsModule, MatDialogModule, MatInputModule, MatButtonModule, MatFormFieldModule],
  templateUrl: './delete-item-dialog.component.html',
  styleUrl: './delete-item-dialog.component.scss'
})
export class DeleteItemDialogComponent {
  title = '';
  description = '';

  constructor(
    public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteItemDialogParameters
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  OkClick() {
    this.dialogRef.close(true);
  }
  CancelClick() {
    this.dialogRef.close(false);
  }
}
