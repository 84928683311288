import { ModuleNames } from "@ag-grid-community/core";
import { EnterpriseCoreModule } from "@ag-grid-enterprise/core";
import { AdvancedFilterComp } from "./advancedFilter/advancedFilterComp.mjs";
import { AdvancedFilterExpressionService } from "./advancedFilter/advancedFilterExpressionService.mjs";
import { AdvancedFilterService } from "./advancedFilter/advancedFilterService.mjs";
import { VERSION } from "./version.mjs";
export const AdvancedFilterModule = {
  version: VERSION,
  moduleName: ModuleNames.AdvancedFilterModule,
  beans: [AdvancedFilterService, AdvancedFilterExpressionService],
  agStackComponents: [{
    componentName: 'agAdvancedFilter',
    componentClass: AdvancedFilterComp
  }],
  dependantModules: [EnterpriseCoreModule]
};