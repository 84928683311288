import { Component, HostBinding, inject } from '@angular/core';

import { ToastService } from './toast-service';
import { NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule, NgTemplateOutlet],
  template: `
    @for (toast of toastService.toasts; track toast) {
      <ngb-toast
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)">
        <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
      </ngb-toast>
    }
  `
})
export class ToastsContainerComponent {
  toastService = inject(ToastService);

  @HostBinding('class') class = 'toast-container position-fixed top-0 end-0 p-3';
  @HostBinding('style') style = 'z-index: 1200';
}
