import { Component, OnInit, inject } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
drilldown(Highcharts);

import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  Column,
  RowClassParams,
  ValueFormatterParams,
  ColumnGroup,
  ColGroupDef
} from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { environment } from '@env/environment';

import { TitleService } from '@services/title-service';
import { GridFilterType } from '@models/gridFilterType';
import { generalExcelStyles } from '@core/AgGridExtensions/ExcelExportStyles';
import { PharmacyQuickOverviewModel } from './models/pharmacyQuickOverviewModel';
import { SavedFiltersComponent } from '../saved-filters/saved-filters.component';
import { SavedFilterItem } from '../saved-filters/models/savedFilterItem';
import { SavedFiltersService } from '@services/saved-filters-service';
import { RecordStatus } from './models/recordStatus';

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  MultiFilterModule,
  RowGroupingModule,
  MenuModule,
  ColumnsToolPanelModule,
  ClipboardModule,
  SetFilterModule
]);

/** 2024.02.20 - JAL - Workaround for missing property on this object (https://github.com/highcharts/highcharts/issues/20673) */
declare module 'highcharts' {
  interface PlotSeriesDataLabelsOptions {
    distance?: number;
  }
}

@Component({
  selector: 'app-pharmacy-overview',
  standalone: true,
  templateUrl: './pharmacy-overview.component.html',
  styleUrl: './pharmacy-overview.component.scss',
  imports: [
    AgGridModule,
    AsyncPipe,
    CommonModule,
    HighchartsChartModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    SavedFiltersComponent
  ]
})
export class PharmacyOverviewComponent implements OnInit {
  baseURL = environment.apiUrl;

  /**
   * The VERSION of the API to use for this component's calls
   */
  apiVersion = '1.0';

  /**
   * Builds and returns the full URL to a data endpoing
   * @param endPoint The name of the FINAL endpoint that we need to call to get/post data
   * @returns 
   */
  getApiUrl(endPoint: string) {
    return `${this.baseURL}v${this.apiVersion}/${endPoint}`;
  }

  /**
   * 2024.02.12 - JAL - I'm pretty sure we aren't using this anymore.
   * Grab a reference to the title service so we can update the page title here.
   */
  titleService = inject(TitleService);

  //#region - Charts -

  public showCharts = false;

  showHideCharts() {
    this.showCharts = !this.showCharts;
  }

  byDrugStatusChartData_ByStatus: Highcharts.PointOptionsObject[] = [
    {
      id: 'IS',
      name: 'In Stock',
      y: 61.04,
      drilldown: 'IS'
    },
    {
      id: 'RW',
      name: 'Return Window',
      y: 9.32,
      drilldown: 'RW'
    },
    {
      id: 'NE',
      name: 'Near Expiration',
      y: 11.02,
      drilldown: 'NE'
    },
    {
      id: 'EX',
      name: 'Expired',
      y: 8.15,
      drilldown: 'EX'
    }
  ];

  byDrugStatusChartData_ByRegion: Highcharts.SeriesPieOptions[] = [
    {
      type: 'pie',
      id: 'IS',
      data: this.byDrugStatusChartData_ByStatus
    },
    {
      type: 'pie',
      id: 'RW',
      data: this.byDrugStatusChartData_ByStatus
    },
    {
      type: 'pie',
      id: 'NE',
      data: this.byDrugStatusChartData_ByStatus
    },
    {
      type: 'pie',
      id: 'EX',
      data: this.byDrugStatusChartData_ByStatus
    }
  ];

  labels: Highcharts.PlotSeriesDataLabelsOptions[] = [
    {
      enabled: true,
      format: '{point.name}'
    },
    {
      enabled: true,
      distance: -30,
      filter: {
        property: 'percentage',
        operator: '>',
        value: 5
      },
      format: '{point.y:.1f}%',
      style: {
        fontSize: '0.9em',
        textOutline: 'none'
      }
    }
  ];

  drillDownData: Highcharts.DrilldownOptions = {
    allowPointDrilldown: true,
    series: this.byDrugStatusChartData_ByRegion
  };

  /**
   * Drug Status chart:
   *  Type: Pie
   *  Title: Drug Status
   *  Layers:
   *    Drug Status
   *    Region
   *    State
   *    Facility
   *    Location
   *
   * Region Chart:
   *  Type: Stacked Bar
   *  Title: By Region
   *  Layers:
   *    Region/Drug Status
   *    State/Drug Status
   *    Faciility/Drug Status
   *    Location/Drug Status
   */

  Highcharts: typeof Highcharts = Highcharts;
  pieChartOptions: Highcharts.Options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Drug Status'
    },
    accessibility: {
      enabled: true,
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      series: {
        dataLabels: this.labels
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },
    series: [
      {
        name: 'Drug Status',
        type: 'pie',
        data: this.byDrugStatusChartData_ByStatus
      }
    ],
    drilldown: this.drillDownData
  };

  //#endregion

  //#region - Saved Filters -

  /**
   * Used to enable/disable the "Save Filters" button. When the user applies a saved filter, we don't
   * want the button to be active because they have ALREADY saved the current filter. We
   * want the user to update the filter in the grid before the "Save Filters" button is (re)enabled.
   */
  allowSaveFilter = false;

  /**
   * Used to enable/disable the 'Saved Filters' component based on if data is loaded at all.
   */
  disableSaveFilter = true;

  /** The name of the saved filter that is currently active: Shown as a placeholder in the 'saved filters' area */
  nameOfActiveSavedFilter = '';

  /**
   * Flag to determine if the filter via the gridAPI via a saved filter or a custom filter column. We
   * want different behavior of the "save filters" button and possibly other objects based on the actual
   * source of a filter change.
   */
  IsSavedFilterApplied = false;

  activeFilterDeleted() {
    this.clearAllFilters();
  }

  applySavedFilter(filter: SavedFilterItem | undefined) {
    if (filter && filter.filterValue !== '') {
      this.nameOfActiveSavedFilter = filter.name;
      this.hasFilterApplied = true;
      this.allowSaveFilter = false;
      this.IsSavedFilterApplied = true;

      switch (filter.filterType) {
        case 'Simple':
          this.changeFilterType('Simple');
          this.gridApi.setFilterModel(JSON.parse(filter.filterValue));
          break;

        case 'Advanced':
          this.changeFilterType('Advanced');
          this.gridApi.setAdvancedFilterModel(JSON.parse(filter.filterValue));
          break;
      }
    }
  }

  saveCurrentFilters() {
    // Exit early if no filter has been applied
    if (!this.gridApi.isAnyFilterPresent() && this.gridApi.getAdvancedFilterModel() === null) {
      return;
    }

    this.filtersService.displaySaveDialog(this.gridApi);
  }

  //#endregion


  currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 4 });

  //#region - Grid -

  /** Reference to the ag-grid's API */
  gridApi!: GridApi<PharmacyQuickOverviewModel>;

  /** The default width to use for date fields in the grid */
  private dateFieldWidth = 150;

  /**
   * Flag to specify if a filter has been applied or not. This is used by controls that should open work/be useable when
   * a filter IS applied.
   */
  hasFilterApplied = false;

  /**
   * Filter type currently being used in the grid.
   */
  filterStyle: GridFilterType = 'None'; // Advanced, Simple

  toggleAdvancedFilter() {
    if (this.filterStyle === 'Advanced') {
      this.changeFilterType('Simple');
    } else {
      this.changeFilterType('Advanced');
    }
  }

  changeFilterType(newType: GridFilterType) {
    // Only do something if the new type type is DIFFERENT from the current value
    if (this.filterStyle !== newType) {
      this.filterStyle = newType;

      switch (this.filterStyle) {
        case 'None':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: false
            },
            enableAdvancedFilter: false
          });
          break;

        case 'Simple':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: true
            },
            enableAdvancedFilter: false
          });
          break;

        case 'Advanced':
          this.gridApi.updateGridOptions({
            defaultColDef: {
              floatingFilter: false
            },
            enableAdvancedFilter: true
          });
          break;
      }
    }
  }

  /** The styles to use during Excel data exports */
  excelStyles = generalExcelStyles;

  /** The column definitions to use on this page */
  gridCols: (ColDef<PharmacyQuickOverviewModel> | ColGroupDef<PharmacyQuickOverviewModel>)[] = [
    {
      headerName: '',
      children: [
        {
          field: 'status',
          headerName: 'Status',
          filter: 'agSetColumnFilter',
          enableRowGroup: true,
          unSortIcon: true,
          cellClassRules: {
            redCell: (params) => {
              return params.value === 'Expired';
            },
            orangeCell: (params) => {
              return params.value === 'Near Expiration';
            },
            yellowCell: (params) => {
              return params.value === 'Return Window';
            }
          }
        },
        {
          field: 'genericName',
          headerName: 'Drug Name (generic)',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'ndcCode',
          headerName: 'NDC Code',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'location',
          headerName: 'Location',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'manufacturer.value',
          headerName: 'Manufacturer',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'distributor',
          headerName: 'Distributor',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'serialNumber',
          headerName: 'Serial #',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'lotNumber',
          headerName: 'Lot #',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'quantity',
          headerName: 'Quantity',
          filter: 'agNumberColumnFilter',
          enableRowGroup: true,
          unSortIcon: true,
          cellClass: 'ag-right-aligned-cell'
        },
        {
          field: 'pricePer',
          headerName: 'Price Per',
          filter: 'agNumberColumnFilter',
          enableRowGroup: true,
          unSortIcon: true,
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams<PharmacyQuickOverviewModel, number>) => {
            if (params.value) {
              return this.currencyFormatter.format(params.value!);
            }
            return '';
          }
        },
        {
          field: 'totalPrice',
          headerName: 'Total Price',
          filter: 'agNumberColumnFilter',
          enableRowGroup: false,
          unSortIcon: true,
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: (params: ValueFormatterParams<PharmacyQuickOverviewModel, number>) => {
            if (params.value) {
              return this.currencyFormatter.format(params.value!);
            }
            return '';
          }
        },
        {
          field: 'expiryDate',
          headerName: 'Expiry',
          cellDataType: 'date',
          filter: 'agDateColumnFilter',
          maxWidth: this.dateFieldWidth,
          minWidth: this.dateFieldWidth,
          width: this.dateFieldWidth,
          enableRowGroup: false,
          unSortIcon: true
        },
        {
          field: 'returnByDate',
          headerName: 'Return By',
          cellDataType: 'date',
          filter: 'agDateColumnFilter',
          maxWidth: this.dateFieldWidth,
          minWidth: this.dateFieldWidth,
          width: this.dateFieldWidth,
          enableRowGroup: false,
          unSortIcon: true
        },
      ]
    }
    ,
    {
      headerName: 'Facility',
      children: [
        {
          field: 'facility.name',
          headerName: 'Name',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'facility.address.city',
          headerName: 'City',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'facility.address.state',
          headerName: 'State',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'facility.region',
          headerName: 'Region',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        }
      ]
    },
    {
      headerName: 'Tracking',
      children: [
        {
          field: 'carrier',
          headerName: 'Carrier',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        },
        {
          field: 'proNumber',
          headerName: 'Tracking #',
          filter: 'agTextColumnFilter',
          enableRowGroup: true,
          unSortIcon: true
        }
      ]
    }
  ];

  /** The TOTAL number of rows in the entire data set */
  totalRowCount = 0;

  /** The text to display to the user that represents the number of results in the grid AND the number of total rows */
  rowCountText = '';

  /**
   * Flag used to know if data was loaded successfully or not so we can disable objects on the page until we DO have
   * data in the grid.
   */
  dataLoaded = false;

  /**
   * Called by ag-grid when the grid first initializes. This is where we grab a reference to the gridAPI object
   * for future use.
   * @param params Event object passed in by ag-grid
   */
  onGridReady(params: GridReadyEvent<PharmacyQuickOverviewModel>) {
    this.gridApi = params.api;

    this.gridApi.updateGridOptions({
      enableAdvancedFilter: false,
      rowSelection: 'single', // allow rows to be selected
      animateRows: true, // have rows animate to new positions when sorted
      suppressMenuHide: true,
      groupDisplayType: 'groupRows',
      rowGroupPanelShow: 'always', // Display the "grouping header" when one or more columns have been grouped
      showOpenedGroup: true,
      headerHeight: 40, // The height of the header
      suppressCsvExport: true, // Don't allow a CSV export
      suppressExcelExport: false, // DO allow an EXCEL export
      defaultExcelExportParams: {
        allColumns: false,
        author: 'Lumatrak',
        sheetName: 'Overview',
        fileName: `PharmacyOverview_${new Date().toISOString().substring(0, 10)}.xlsx`
      },
      getRowClass: this.getRowCssClass
    });

    this.changeFilterType('Simple');
  }

  /**
   * Called by ag-grid when any filter is added, removed, or edited. This function
   * allows up to perform actions based on things like row count and set/remove styles
   * during Excel export.
   * @param params Event object passed in by ag-grid
   */
  onFilterChanged(params: FilterChangedEvent<PharmacyQuickOverviewModel>) {
    this.displayRecordCount();

    // Determine what type of filter is being used
    switch (params.source) {
      case 'api':
        if (this.IsSavedFilterApplied) {
          this.hasFilterApplied = true;
          this.allowSaveFilter = false;
        } else {
          this.nameOfActiveSavedFilter = '';
          this.hasFilterApplied = params.api.isAnyFilterPresent();
          this.allowSaveFilter = true;
        }
        break;

      case 'advancedFilter': {
        const advModel = params.api.getAdvancedFilterModel();

        // Set the "has filter" flag
        this.hasFilterApplied = advModel !== null;
        this.nameOfActiveSavedFilter = '';
        this.allowSaveFilter = this.hasFilterApplied;
        break;
      }

      case 'quickFilter':
      case 'columnFilter': {
        // This is to update the style of a column header when we do an Excel Export. This change
        // ISN'T reflected on the webpage itself since other CSS clases are used for that instead.
        const appliedFilters = params.api.getFilterModel();
        const filteredColumnsMap = new Map<string, boolean>();
        for (const key in appliedFilters) {
          filteredColumnsMap.set(key, true);
        }
        const allColumns = params.api.getColumns() as Column[];
        allColumns.forEach((col) => {
          const colId = col.getColId();
          if (filteredColumnsMap.has(colId)) {
            params.api.getColumnDef(colId)!.headerClass = 'filteredColumn';
          } else {
            params.api.getColumnDef(colId)!.headerClass = 'unfilteredColumn';
          }
        });

        // Set the "has filter" flag
        this.hasFilterApplied = filteredColumnsMap.size > 0;
        this.nameOfActiveSavedFilter = '';
        this.allowSaveFilter = this.hasFilterApplied;
        break;
      }
    }

    // Set this back to false no matter what
    this.IsSavedFilterApplied = false;
  }

  /**
   * Executed by ag-grid the FIRST time data is loaded into it. Here we want to perform
   * actions to help better display the data to the user.
   * @param event Event object passed in by ag-grid
   */
  onFirstDataRendered(event: FirstDataRenderedEvent<PharmacyQuickOverviewModel>) {
    this.displayRecordCount();
    event.api.autoSizeAllColumns();
  }

  /**
   * Displays the current record count including number matching any active filter and total record count
   */
  displayRecordCount() {
    if (this.gridApi.isAnyFilterPresent()) {
      this.rowCountText = `${this.gridApi.getModel().getRowCount().toLocaleString()} of ${this.totalRowCount.toLocaleString()}`;
    } else {
      this.rowCountText = `${this.totalRowCount.toLocaleString()}`;
    }
  }

  /**
   * Determine what CSS class (if any) should be applied to a given row
   * @param params The parameters paseed in by ag-grid to help select the CSS class to return for a row;
   * @returns The CSS class name to apply to the row
   */
  getRowCssClass(params: RowClassParams<PharmacyQuickOverviewModel>): string {
    switch (params.data?.status) {
      case 'Expired':
        return 'redCell';

      case 'Near Expiration':
        return 'orangeCell';

      case 'Return Window':
        return 'yellowCell';
    }
    return '';
  }

  //#endregion

  constructor(
    private http: HttpClient,
    private filtersService: SavedFiltersService
  ) {}

  ngOnInit() {
    this.loadRecordsFromServer();
  }

  /**
   * Calculates and returns the 'status' for a pharmacy record
   * @param record The pharmacy record to process
   * @param today The date that represents "today" to determine if the drug is expired or not.
   * @param expirationRange The date that represents the start of the "window" of when the drug is close to expiring and is (probably?) outside of the return window.
   * @param returnWindowRange The date that represents the start of the "window" of when the drug SHOULD be returned IF it WILL be returned.
   * @returns The appropiate RecordStatus value for this record
   */
  getDrugRowStatus(
    record: PharmacyQuickOverviewModel,
    today: Date,
    expirationRange: Date,
    returnWindowRange: Date
  ): RecordStatus {
    if (record.expiryDate! < today) {
      return 'Expired';
    }
    if (record.expiryDate! < expirationRange) {
      return 'Near Expiration';
    }
    if (record.returnByDate! < returnWindowRange) {
      return 'Return Window';
    }

    return 'In Stock';
  }

  /**
   * Loads the data in the grid from the server.
   */
  loadRecordsFromServer() {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');

    this.http.get<PharmacyQuickOverviewModel[]>(this.getApiUrl('Pharmacy/Overview'), { headers: headers }).subscribe({
      next: (data) => {
        /**
         * The dates to use to calculate the status for each record. These might come from the API
         * later (and we might have different ranges for different drugs) but for now they are hardcoded.
         */
        const today = new Date();
        const expirationRange = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() + 60);
        const returnWindowRange = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() + 30);

        /**
         * Calculate any and all client side values for the drugs
         */
        data.map((x) => {
          x.status = this.getDrugRowStatus(x, today, expirationRange, returnWindowRange);
          x.totalPrice = x.quantity * x.pricePer;
          return x;
        });

        console.info(JSON.stringify(data));

        /**
         * Assign the data to the grid and perform any flag operations to enable/disable UI controls
         */
        this.gridApi.setGridOption('rowData', data);
        this.totalRowCount = this.gridApi.getModel()?.getRowCount() ?? 0;
        if (this.totalRowCount > 0) {
          this.dataLoaded = true;
          this.disableSaveFilter = false;
        } else {
          this.dataLoaded = false;
          this.disableSaveFilter = true;
        }
      },
      error: (e) => {
        console.error(e);
        this.dataLoaded = false;
        this.disableSaveFilter = true;
      }
    });
  }

  /**
   * Removes all filters from the grid
   */
  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.setAdvancedFilterModel(null);

    // Disable all buttons that would allow a user to save/edit/clear current filters
    this.hasFilterApplied = false;

    // Clear the name of the active saved filter since no filters are currently applied
    this.nameOfActiveSavedFilter = '';
  }

  /**
   * Exports the grid data as an Excel file
   */
  exportToExcel() {
    this.gridApi.exportDataAsExcel();
  }
}
