import { GridApi } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SavedFilterItem } from 'app/components/saved-filters/models/savedFilterItem';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SavedFiltersService {
  // Subject sources
  private showSaveDialogSource = new Subject<GridApi>();

  // Observable output
  showSaveDialog$ = this.showSaveDialogSource.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Tell the 'saved filters' component to display a 'save filter' dialog so the user can save
   * the current filters off for future use.
   * @param gridApi The api of the grid who needs its currently applied filter set to be
   * saved.
   */
  displaySaveDialog(gridApi: GridApi) {
    this.showSaveDialogSource.next(gridApi);
  }

  /**
   * Retrieve the list of saved filters for a specific grid (and user)
   * @param gridId The id of the grid whose filters are needed
   * @returns The list of saved filters for the grid AND user (which is indentified on the server
   * instead of client side code).
   */
  getSavedFilters(gridId: string) {
    const body = {
      gridId: gridId
    };
    return this.http.post<SavedFilterItem>('url', JSON.stringify(body));
  }
}
